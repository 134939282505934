<template>
  <v-chart class="chart" :option="option" />
  <div class="legend">Bray–Curtis dissimilarity of the predictions at the <i>{{rank}}</i> level. 0=identical, 1=dissimilar</div>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart } from "echarts/charts";
import { VisualMapComponent } from "echarts/components";
import { HeatmapChart } from "echarts/charts"
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { ref, defineComponent } from "vue";

use([
  CanvasRenderer,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  VisualMapComponent,
  HeatmapChart
]);

export default defineComponent({
  name: "Heatmap",
  props: ['instance', 'sample', 'rank'],
  components: {
    VChart
  },
  provide: {
    [THEME_KEY]: "light"
  },
  created() {
    fetch(`data/${this.sample}.pairwise.json`)
      .then(res => {
        const json_data=res.json();
        return json_data
        })
      .then(json_data => this.create_plot(json_data, this.$props.rank))
  },
  data () {
    return {
      option: {
        },
      json_data: {},
      plot_data: [],
      labels: []
    }
  },
  watch: {
      rank (val) {
        this.create_plot(this.json_data, val)
      }
    },
  methods: {
    create_plot (json_data, rank) {
        // keep the loaded data
        this.json_data=json_data
        const tools=[]
        const data=[]
        for (var idx in json_data) {
          if (json_data[idx].Rank == rank){
            if (!tools.includes(json_data[idx].A)) {
                tools.push(json_data[idx].A)
             }
           }
        }

        var i = -1
        var j = 0
        for (var idx in json_data) {
          if (json_data[idx].Rank == rank){
            if(i<Object.keys(tools).length-1){
                i=i+1
            }
            else {
                i=0
                j=j+1
            }
            data.push([i,j,json_data[idx].Distance])
          }
        }

  this.option = ref({

  grid: {
    height: '50%',
    top: '10%'
  },
  xAxis: {
    type: 'category',
    data: tools,
    splitArea: {
      show: true
    },
    axisLabel: { interval: 0, rotate: 45 }
  },
  yAxis: {
    type: 'category',
    data: tools,
    splitArea: {
      show: true
    },
    axisLabel: { interval: 0, rotate: 45 }
  },
  visualMap: {
    min: 0,
    max: 1,
    calculable: true,
    orient: 'horizontal',
    left: 'center',
    bottom: '15%',
    formatter: function (params) {
      return `${params.toExponential(1)}`;
    }
  },
  series: [
    {
      type: 'heatmap',
      data: data,
      label: {
        show: true,
        formatter: function (params) {
            return `${params.data[2].toExponential(1)}`;
        }
      },
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      }
    }
  ]

        });
      }
  }
});
</script>

<style scoped>
.chart {
  height: 28em;
}
.legend {
  color: black;
  font-size:0.8em;
  padding-top:0.5em;
  margin-top:0.5em;
  text-align:left;
  border: 0 solid black;
  border-top-width: 1px;
}

</style>
