<template>
    <div></div>
    <br/>
</template>

<script>
export default {
  name: 'Ranking',
  props: {
    instance: String,
    sample: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
  display: inline;
  font-size:5vw;
  width:70%;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.tmp {
  background-color:#cccccc;
  color:white;
  height:24em;
}
</style>
