const invertMetric = (data, tools, metric) => {
  var minval = null
  for (let t in tools) {
    var toolname = tools[t];
    var val = data[toolname][metric]
    if (minval == null || val < minval) {
      minval = val;
    }
  }
  for (let t in tools) {
    var toolname = tools[t];
    var val = data[toolname][metric]
    data[toolname][metric] = minval / val
  }
}

export default invertMetric