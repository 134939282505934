<template>
    <div class="textbox">
      <h4>Features of this instance:</h4>
      <table>
        <tr><th>The target organisms are </th><td :class="box_content.target">{{ box_content.target }}</td></tr>
        <tr><th>The type of sample is </th><td :class="box_content.scope">{{ box_content.scope }}</td></tr>
        <tr v-if="box_content.conta"><th>With contaminants and host?</th><td :class="box_content.conta">{{ box_content.conta }}</td></tr>
        <tr><th>The sequencing tech. is </th><td :class="box_content.reads">{{ box_content.reads }}</td></tr>
        <tr><th>The taxonomy is </th><td :class="box_content.taxonomy">{{ box_content.taxonomy }}</td></tr>
        <tr><th>Are unknown taxa simulated? </th><td :class="box_content.unknown">{{ box_content.unknown }}</td></tr>
        <tr><th>With low abundance targets?</th><td :class="box_content.low">{{ box_content.low }}</td></tr>
        <tr><th>Sampling cutoff date ?</th><td class="date" :class="box_content.date">{{ box_content.date }}</td></tr>
        <tr v-if="box_content.rep"><th>Nb of provided ref. genome per species is </th><td>{{ box_content.rep }}</td></tr>
        <tr><th>Tools with embed references are </th><td :class="box_content.ref">{{ box_content.ref }}</td></tr>
        <tr v-if="box_content.hardware"><th>Computational ressources are </th><td >{{ box_content.hardware }}</td></tr>

      </table>
    </div>
</template>

<script>
export default {
  name: 'IDCard',
  props: {
    instance: String,
    sample: String,
  },
  data() {
    return {
      box_content: {
        "title": "",
        "text": ""
      }
    }
  },
  mounted() {
      fetch(`data/${this.sample}.description.json`)
        .then(res => res.json())
        .then(data => this.box_content = data)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.textbox {
  margin:0;
  padding:1em;
  width:14em;
  background-color: #F8FBFD;
  text-align:left;
  border:0 solid #0288d1;
  border-radius: 1em;
  border-left: 0;
  border-right: 0;
  height:22em;
  color:black;
  overflow-y:scroll;
}
.paragraph {
  font-size:0.9em;
  margin-bottom:0.9em;
  text-align:left;
}

.floating_block td {
  font-size: 0.7em;
  font-weight: 500;
  text-align: center;
  padding: 0;
  margin: 0;
  border: 0;
  padding:0.1em 0.15em;
  min-width:6.6em;
}

.NCBI {
  background-color: #0288d1;
  color:white;
}

.GTDB {
  background-color: #d84315;
  color:white;
}

.yes {
  background-color: #0288d1;
  color:white;
}

.date {
  background-color: #0288d1;
  color:white;
}

.no {
  background-color: #d84315;
  color:white;
}

.excluded {
  background-color: #d84315;
  color:white;
}

.included {
  background-color: #0288d1;
  color:white;
}


.Illumina {
  background-color: #0288d1;
  color:white;
}

.ONT {
  background-color: #d84315;
  color:white;
}

.clinical {
  background-color: #0288d1;
  color:white;
}

.environmental {
  background-color: #d84315;
  color:white;
}

.prokaryotes {
  background-color: #0288d1;
  color:white;
}

.viruses {
  background-color: #111;
  color:white;
}

.eukaryotes {
  background-color: #d84315;
  color:white;
}

.floating_block th {
  font-size: 0.7em;
  font-weight: 500;
  text-align: right;
  padding: 0.2em 0.5em;
  margin: 0;
  border: 0;
}

.floating_block tr {
  padding: 0;
  margin: 0;
  background-color: #FFF;
}

.floating_block table {
    border-spacing: 0px;
    border-collapse: collapse;
    margin-top: 0.2em;
    margin-bottom: 1em;
    width: 100%;
    border: 1px solid black;
}

</style>
