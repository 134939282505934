<template>
    <div class="table">
    <div class="legend">Minimal number of reads used to consider a taxon as being present at the <i>{{rank}}</i> level in the evaluation samples. This lower detection limit maximizes the F1-score on the calibration samples. It is used to compute all metrics related to the quality of the predictions so each tool is evaluated with its individual optimized detection threshold. Note: this is the best threshold defined by LEMMI given the tool predictions; if a tool has an internal filtering value set that maximizes the F1 without extra filtering, the value 0 will be reported by LEMMI here.</div>
    <table>
    <tr><th>Tool name</th><th>Value</th></tr>
    <tr v-for="index in get_index_to_display">      
      <td>{{ table_content.toolname[index] }}</td>
      <td class="right">{{ minus_into_zero(table_content.value[index]) }}</td>
    </tr>
    </table>
    </div>
</template>

<script>
export default {
  name: 'FilteringThresholds',
  props: {
    instance: String,
    sample: String,
    rank: String
  },
  data() {
    return {
      table_content: {}
    }
  },
  computed: {
    get_index_to_display: function () {
      var toolnames = this.table_content.toolname
      var key_list = []
      for (var key in toolnames) {
        if (this.table_content.rank[key] == this.rank){
            key_list.push(key);
        }
      }
      return key_list
    },
    minus_into_zero: function () {
      return (val) => val >= 0 ? val : 0;
    }
  },
  mounted() {
      fetch(`data/${this.instance}.filtering_threshold.json`)
        .then(res => res.json())
        .then(data => this.table_content = data)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.table {
  margin:0;
  padding:0;
  width:14em;
  background-color: #FFF;
  text-align:left;
  border:0;
  height:22em;
  color:black;
  text-align:left;
}
.table th {
  background-color:#FFF;
  color:black;
  padding:1em;
  border-radius: 1em;
}
.table td {
  background-color:#FFF;
  padding:0;
  margin:0;
}

.table td.right {
  text-align: right;
}

.table tr {
  padding:0;
  margin:0;
}
.paragraph {
  font-size:0.9em;
  margin-bottom:0.9em;
  text-align:left;
}
h4 {
  padding:0;
  margin:0;
}
.legend {
  color: black;
  font-size:0.8em;
  padding-bottom:0.5em;
  margin-bottom:0.5em;
  text-align:left;
  border: 0 solid black;
  border-bottom-width: 1px;
}
</style>
