<template>
    <div class="table">
    <table>
    <tr><th>Tool name</th><th>Minimal nb of reads to consider a taxa</th></tr>
    <tr v-for="(toolname, index) in table_content.toolname">
      <td v-if="table_content.rank[index] === rank">{{ toolname }}</td>
    </tr>
    </table>
    </div>
</template>

<script>
export default {
  name: 'Composition',
  props: {
    instance: String,
    sample: String,
    rank: String
  },
  data() {
    return {
      table_content: {
        "title": "",
        "text": ""
      }
    }
  },
  mounted() {
      fetch(`data/${this.instance}.json`)
        .then(res => res.json())
        .then(data => this.table_content = data)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.table {
  margin:0;
  padding:0;
  width:14em;
  background-color: #FFF;
  text-align:left;
  border:0.3em solid #0288d1;
  border-radius: 1em;
  border-left: 0;
  border-right: 0;
  height:22em;
  color:black;
  text-align:center;
}
.table th {
  background-color:#F8FBFD;
  color:black;
  padding:1em;
  border-radius: 1em;
}
.table td {
  background-color:#F8F8F8;
  padding:0;
  margin:0;
}
.table tr {
  padding:0;
  margin:0;
}
.paragraph {
  font-size:0.9em;
  margin-bottom:0.9em;
  text-align:left;
}
</style>
