<template>
   <img width="20" :src=picture_src />
</template>
<script>
export default {
    data: function () {
        return {
            picture_src: ''
        };
    },
    beforeMount() {
        this.picture_src = (this.params.value === true) ? 'img/check.png' : 'img/wrong.png';
    }
};
</script>