const rankMetric = (data_arr, metric, order) => {
  const metric_arr  =
    data_arr.sort((a, b) => {
      if (order == "desc") {
        return b[metric] - a[metric];
      }
      else if (order == "asc") {
        return a[metric] - b[metric];
      }
      else {
        throw Error("order must be 'asc' or 'desc'");
      }
    });

  for (let v in metric_arr) {
    metric_arr[v][metric + "_rank"] = parseInt(v) + 1;
  }
  return metric_arr
}

export default rankMetric
