<template>
  <v-chart class="chart" :option="option" />
  <div class="legend"><b>Precision-Recall curve</b> when filtering low ab. taxa at the <i>{{ rank }}</i> level. The pins mark the location where the F1-score is maximized on each curve, which defines the thresholds used in the evaluation samples above (taking the median value of all calibration sample thresholds). Each other point is one filtering attempt by LEMMI; hover them to display the corresponding threshold (min. nb of reads).</div>
</template>

<script>
import { use } from "echarts/core";
import { GridComponent } from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import VChart, { THEME_KEY } from "vue-echarts";
import { ref, defineComponent } from "vue";
import 'echarts/lib/component/markPoint'

use([
  GridComponent, LineChart, CanvasRenderer, UniversalTransition
]);

export default defineComponent({
  name: "Prc",
  props: ['instance', 'sample', 'rank'],
  components: {
    VChart
  },
  provide: {
    [THEME_KEY]: "light"
  },
  created() {
    fetch(`data/${this.instance}.prc.json`)
      .then(res => {
        const json_data=res.json();
        return json_data
        })
      .then(json_data => this.create_plot(json_data, this.$props.rank))
  },
  watch: {
    rank (val) {
      this.create_plot(this.json_data, val)
    }
  },
  data () {
    return {
      option: {
        },
      json_data: {},
      plot_data: [],
      pin_coord: [],
      legends: []
    }
  },
  methods: {
    create_plot (json_data, rank) {
        // keep the loaded data
        this.json_data=json_data
        // set the filtered data
        this.filtered_data=this.json_data
        this.plot_data = []

        var data_as_dict = {}
        if (typeof json_data.toolname !== 'undefined') {
          for(var i in Object.keys(json_data.toolname)) {
              data_as_dict[json_data.toolname[i]] = []
          }
        }

        var max_f1={}
        var threshold_coord={}
        var unique_tools=[]
        
        if (typeof json_data.toolname !== 'undefined') {
          for (var i in Object.values(json_data.toolname)) {
            if (json_data.toolname[i] in unique_tools === false){
              unique_tools.push(json_data.toolname[i])
            }
          }
        }

        for (var tool in unique_tools){
          max_f1[unique_tools[tool]] = 0
          threshold_coord[unique_tools[tool]] = [0,0]
        }
        if (typeof json_data.toolname !== 'undefined') {
          for(var i in Object.keys(json_data.toolname)) {
            if (json_data.sample[i] == this.sample){
              if (json_data.rank[i] == rank){
                  var f1=(2*(json_data.precision[i]*json_data.recall[i])/(json_data.precision[i]+json_data.recall[i]))
                  if (f1 > max_f1[json_data.toolname[i]]){
                    max_f1[json_data.toolname[i]]=f1
                    threshold_coord[json_data.toolname[i]]=[json_data.precision[i], json_data.recall[i]]
                  }
                  data_as_dict[json_data.toolname[i]].push([json_data.precision[i], json_data.recall[i], json_data.threshold[i]])
              }
            }
          }
        }
        for(var tool in data_as_dict){
          this.legends.push(tool)
          this.plot_data.push(
            {
              type: 'line',
              showSymbol: true,
              symbolSize: 1,
              clip: false,
              data: data_as_dict[tool],
              name: tool,
              lineStyle: {
              normal: {
                  width: 0.5,
                }
              },
              markPoint: {
                data: [{
                  coord: threshold_coord[tool]
                }],
                symbol: 'pin',
                symbolSize: 15,
                silent: true
              },
            }
          )
        }

        var callback = (args) => {
          return String(args.data[2])
        }

        this.option = ref({
            legend: {
              data: this.legends,
              top: 'top'
            },
            tooltip: {
              trigger: 'item',
              formatter: callback
            },
            yAxis: {
              type: 'value',
              name: 'Recall',
              nameLocation: 'middle',
              nameGap: 15,
              max: 1.0,
              axisLabel: {
                fontSize: 10
              },
              nameTextStyle: {
                fontSize: 10
              }
            },
            xAxis: {
              type: 'value',
              name: 'Precision',
              nameLocation: 'middle',
              nameGap: 20,
              max: 1.0,
              axisLabel: {
                fontSize: 10
              },
              nameTextStyle: {
                fontSize: 10
              }
            },
            grid: {
                right: '5%',
                bottom: '3%',
                top: '35%',
                left: '5%',
                containLabel: true
            },
            series: this.plot_data
        });
      }
  }
});
</script>

<style scoped>
.chart {
  height: 400px;
}
.legend {
  color: black;
  font-size:0.8em;
  padding-top:0.5em;
  margin-top:0.5em;
  text-align:left;
  border: 0 solid black;
  border-top-width: 1px;
}
</style>
