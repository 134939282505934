<template>
  <a id="top" class="anchor"></a>
  <div class="table">
    <ToolSummary v-for="(details,name) in tools" :tool="name" :content="details" class="tool_summary"/>
    </div>
    <div class="spacer"></div>
  <Footer/>
</template>

<script>
// @ is an alias to /src
import ToolSummary from '@/components/ToolSummary.vue'
import Footer from '@/components/Footer.vue'
import contentStructure from "@/../public/data/tools.json";

export default {
  name: 'Home',
  components: {
    ToolSummary,
    Footer
  },
  data () {
      return { 
        tools: contentStructure
      }
  }
}

</script>

<style scoped>
.description {
  text-align: left;
  background-color:#F8FBFD;
  border:0;
  border-radius: 1em;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  width:auto;
  padding:1em;
  border-radius: 0.5em;
  margin:0;
  margin-top:0.5em;
}
.anchor {
   position: relative;
   top: -10em;
}
.spacer {
  clear:both;
  height:2.2em;
}
</style>
