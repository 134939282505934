<template>
<div class="left">
<InstanceDetails :instance="comparison_instance" :structure="structure"/>
</div>
<div class="right">
<InstanceDetails :instance="instance" :structure="structure"/>
</div>
<Footer/>
</template>

<script>

import InstanceDetails from '@/views/InstanceDetails.vue'
import Footer from '@/components/Footer.vue';
export default {
  name: "InstanceComparison",
  props: {
    comparison_instance: Object,
    instance: String
  },
  components: {
    InstanceDetails,
    Footer
  }
}
 
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.left {
  float:left;
  width:49%;
  border:0 dashed black;
  border-right-width:1px;
}
.right {
  float:right;
  width:50%;
}
</style>
