<template>
    <div class="legend"><b>Dynamic ranking of tools based on the selected metrics</b> at the <i>{{rank}}</i> level. The average value accross samples for each selected metric and each tool are turned into ranks. The list reflects the arithmetic mean of these ranks for each tool.</div>
  <ul>
    <li v-for="metric in metrics" align="left">
      <label>
        <input
          type="checkbox"
          v-model="selected_metrics"
          :value="metric"           
        />
        {{ nice_label(metric) }}
      </label>
    </li>
  </ul>
  <p>
  </p>
  
  <ag-grid-vue
    style="width: 20em; height: 25em;"
    class="ag-theme-alpine"
    :columnDefs="columnDefs"
    :rowData="rowData"
  >
  </ag-grid-vue>
</template>

<script>
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import getToolData from "../../composables/getToolData";
import getMetricData from "../../composables/getMetricData";
import rankMetric from "../../composables/rankMetric";
import { AgGridVue } from "ag-grid-vue3";
import { computed, ref, watchEffect } from "@vue/runtime-core";

export default {
  name: "RankingTable",
  props: ["instance", "metrics", "rank"],
  components: {
    AgGridVue,
  },
  computed: {
    nice_label: function () {
      var nice_val = {precision:"Precision", recall:"Recall", l2:"Abundance (L2)", memory_analysis:"Memory", runtime_analysis:"Runtime", f1:"F1-Score"};
      return (val) => nice_val[val];
    }
  },
  setup(props) {
    const tools = ref([]);
    const tool_data = ref({});
    const columnDefs = ref([]);
    const rowData = ref([]);

    const metrics = [
      "f1",
      "precision",
      "recall",
      "l2",
      "runtime_analysis",
      "memory_analysis"
    ];
    const selected_metrics = ref([
      "f1",
      "l2"
      ]);

    const load = async () => {
      try {

        let data = await fetch(`data/${props.instance}.tools.json`);
        if (!data.ok) {
          throw Error("no data available");
        }
        tools.value = await data.json();
        columnDefs.value.push({
          field: "name",
          headerName: "Name / Mean rank",
          resizable: false,
          wrapText: true,
          autoWidth: false,
          autoHeight: false,
          maxWidth: 170,
          cellStyle: { wordBreak: "normal", textAlign: "left" },
        });
        columnDefs.value.push({
          field: "mean_rank",
          headerName: "",
          sort: "asc",
          sortable: false,
          resizable: false,
          wrapText: true,
          autoHeight: false,
          maxWidth: 100,
          cellStyle: { wordBreak: "normal", textAlign: "left" },
        });
      } catch (err) {
        error.value = err.message;
        //console.log(error.value);
      }
    };
    load();

    const { metric_data, error, load_metric_data } = getMetricData(
      props.instance,
      metrics
    );

    load_metric_data();

    watchEffect(() => {
      rowData.value = [];
      for (let t in tools.value) {
        var toolname = tools.value[t];
        tool_data.value[toolname] = getToolData(
          metric_data.value,
          toolname,
          props.instance,
          props.rank
        );
      }

      var data_arr = [];
      for (let [key, value] of Object.entries(tool_data.value)) {
        value["toolname"] = key;
        data_arr.push(value);
      }

      for (let m of selected_metrics.value) {
        if (m == "memory_analysis" | m == "runtime_analysis" | m == "l2") {
          var order = "asc"
        }
        else {
          var order = "desc"
        }
        data_arr = rankMetric(data_arr, m, order)
      }

      for (let v in data_arr) {
        var scores = []
        for (let met of selected_metrics.value) {
          scores.push(parseFloat(data_arr[v][met + "_rank"]))
        }
        
        if (selected_metrics.value.length) {
          var sum_rank = scores.reduce((a,b)=>a+b)
          var mean_rank = sum_rank / parseFloat(selected_metrics.value.length)
        }
        else {
          var mean_rank = 0.0
        }
        
        data_arr[v]["mean_rank"] = mean_rank;
        rowData.value.push({
          name: data_arr[v]["toolname"],
          mean_rank: mean_rank,
        });
      }
    });

    return {
      columnDefs,
      rowData,
      metrics, 
      selected_metrics
    };
  },
};
</script>

<style scoped>
p {
  color: black
}
label {
  color: black;
  font-weight: bold;
}
li {
  float:left;
  list-style-type: none;
  color: black;
  font-size:0.8em;
  padding:0.2em;
  padding-bottom: 0.5em;
  margin:0;
  text-align:left;
}
li label {
  font-weight: 100;
}
.ag-theme-alpine {
  clear:both;
}
.ag-fresh .ag-header-cell-label .ag-header-cell-text
{
display: inline-block;
text-align: right;
}
.legend {
  color: black;
  font-size:0.8em;
  padding-top:0.5em;
  margin-top:0.5em;
  text-align:left;
  border: 0 solid black;
  border-bottom-width: 1px;
}
</style>
