const getToolData = (metric_data, toolname, instance, rank) => {
  const tool_data = {}
  var metrics = Object.keys(metric_data)

  for (let m in metrics) {
    var metric = metrics[m]
    var tool_arr = metric_data[metric]["toolname"];
    var instance_arr = metric_data[metric]["sample"];
    var rank_arr = metric_data[metric]["rank"];
    var score_arr = metric_data[metric]["value"];

    for (let k in instance_arr) {
      let tool = tool_arr[k];
      if (tool == toolname) {
        var inst = instance_arr[k];
        if (inst == instance) {
          if (rank_arr) {
            if (rank_arr[k] == rank) {
              tool_data[metric] = score_arr[k]
            }
          }
          else {
            tool_data[metric] = score_arr[k]
          }
        }
      }
    }
  }
  //console.log(tool_data)
  return tool_data
};

export default getToolData