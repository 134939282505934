<template>
      <div class="legend">True abundance estimation (<b>L2 distance to truth</b>) at the <i>{{rank}}</i> level. Average here represents tool ranks not values. See individual samples below for actual values.</div>
    <div class="table">
    <table>
    <tr><th>Tool name</th><th>Rank</th></tr>
    <tr v-for="index in get_index_to_display">      
      <td>{{ table_content.toolname[index] }}</td>
      <td class="right">{{ table_content.value[index] }}</td>
    </tr>
    </table>
    </div>
</template>

<script>
export default {
  name: 'l2_rank',
  props: {
    instance: String,
    sample: String,
    rank: String
  },
  data() {
    return {
      table_content: {}
    }
  },
  computed: {
    get_index_to_display: function () {
      var toolnames = this.table_content.toolname
      var key_list = []
      for (var key in toolnames) {
        if (this.table_content.sample[key] == this.instance && this.table_content.rank[key] == this.rank){
            key_list.push(key);
        }
      }
      return key_list.reverse()
    }
  },
  mounted() {
      fetch(`data/${this.instance}.l2.json`)
        .then(res => res.json())
        .then(data => this.table_content = data)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.table {
  margin:0;
  padding:0;
  width:14em;
  background-color: #FFF;
  text-align:left;
  border:0;
  height:22em;
  color:black;
  text-align:left;
}
.table th {
  background-color:#FFF;
  color:black;
  padding:1em;
  border-radius: 1em;
}
.table td {
  background-color:#FFF;
  padding:0;
  margin:0;
}

.table td.right {
  text-align: right;
}

.table tr {
  padding:0;
  margin:0;
}
.paragraph {
  font-size:0.9em;
  margin-bottom:0.9em;
  text-align:left;
}
h4 {
  padding:0;
  margin:0;
}
.legend {
  color: black;
  font-size:0.8em;
  padding-bottom:0.5em;
  margin-bottom:0.5em;
  text-align:left;
  border: 0 solid black;
  border-bottom-width: 1px;
}

</style>
