<template>
  <div id="taxo_selection">
  <span class="taxonomic_rank_label">Taxonomic level:</span>
  <div v-for="value in ranks">
    <button
      :key="value"
      :class="{ active: value === this.selected_rank }"
      class="rank_selector"
      @click="change_rank(value)"
    >
      {{ value }}
    </button>
  </div>
  </div>
  <div>
  <h2>
      <span class="highlight">{{ instance }} (<a :href="`data/${instance}.yaml`" target="_blank">Config file</a>)</span><br/><!--<button class="share" @click="copyURL">Share these results</button><button class="getconfig" @click="getConfig">Get the config files to replicate the results</button>-->
  </h2>
  </div>
  <div class="content">
  <div v-for="(value, sample) in structure[instance]">
    <div class="wide_block">
      <h3 :id="sample" v-if="sample == instance">
        Average across all evaluation samples<span v-bind:class="[isCollapsed[sample] ? 'collapsed' : 'expanded']" class="tuto_collapse">Details by samples are below<br />You can collapse this block =></span><span
          v-bind:class="[isCollapsed[sample] ? 'collapsed' : 'expanded']"
          class="toggle_button"
          @click="toggle_display(sample)"
          >&minus;</span
        ><span
          v-bind:class="[isCollapsed[sample] ? 'expanded' : 'collapsed']"
          class="toggle_button"
          @click="toggle_display(sample)"
          >&plus;</span
        >
      </h3>
      <h3 :id="sample" v-else-if="sample.search('-e') > -1">
        Evaluation sample <span class="highlight">{{ sample }}</span
        ><span
          v-bind:class="[isCollapsed[sample] ? 'collapsed' : 'expanded']"
          class="toggle_button"
          @click="toggle_display(sample)"
          >&minus;</span
        ><span
          v-bind:class="[isCollapsed[sample] ? 'expanded' : 'collapsed']"
          class="toggle_button"
          @click="toggle_display(sample)"
          >&plus;</span
        >
      </h3>
      <h3 :id="sample" v-else-if="sample.search('-c') > -1">
        Calibration sample <span class="highlight">{{ sample }}</span
        ><span
          v-bind:class="[isCollapsed[sample] ? 'collapsed' : 'expanded']"
          class="toggle_button"
          @click="toggle_display(sample)"
          >&minus;</span
        ><span
          v-bind:class="[isCollapsed[sample] ? 'expanded' : 'collapsed']"
          class="toggle_button"
          @click="toggle_display(sample)"
          >&plus;</span
        >
      </h3>
      <div class="instance_summary_description">
        <div
          v-for="value2 in structure[instance][sample]"
          class="floating_block"
          v-bind:class="[isCollapsed[sample] ? 'collapsed' : 'expanded', value2.split(':')[1] == 'full' ? 'full_width' : '']"
        >
          <Precision
            v-if="value2.split(':')[0] == 'precision'"
            :instance="instance"
            :sample="sample"
            v-bind:rank="selected_rank"
          />
          <Recall
            v-else-if="value2.split(':')[0] == 'recall'"
            :instance="instance"
            :sample="sample"
            v-bind:rank="selected_rank"
          />
          <Tp
            v-else-if="value2.split(':')[0] == 'tp'"
            :instance="instance"
            :sample="sample"
            v-bind:rank="selected_rank"
          />
          <Fp
            v-else-if="value2.split(':')[0] == 'fp'"
            :instance="instance"
            :sample="sample"
            v-bind:rank="selected_rank"
          />
          <f1
            v-else-if="value2.split(':')[0] == 'f1'"
            :instance="instance"
            :sample="sample"
            v-bind:rank="selected_rank"
          />
          <l2
            v-else-if="value2.split(':')[0] == 'l2'"
            :instance="instance"
            :sample="sample"
            v-bind:rank="selected_rank"
          />
          <l2_rank
            v-else-if="value2.split(':')[0] == 'l2_rank'"
            :instance="instance"
            :sample="sample"
            v-bind:rank="selected_rank"
          />
          <Memory
            v-else-if="value2.split(':')[0] == 'memory'"
            :instance="instance"
            :sample="sample"
          />
          <MemoryRef
            v-else-if="value2.split(':')[0] == 'memory_ref'"
            :instance="instance"
            :sample="sample"
          />
          <RuntimeRef
            v-else-if="value2.split(':')[0] == 'runtime_ref'"
            :instance="instance"
            :sample="sample"
          />
          <Runtime
            v-else-if="value2.split(':')[0] == 'runtime'"
            :instance="instance"
            :sample="sample"
          />
          <RankingTable
            v-else-if="value2.split(':')[0] == 'ranking'"
            :instance="instance"
            v-bind:rank="selected_rank"
          />
          <Selector
            v-else-if="value2.split(':')[0] == 'selector'"
            :instance="instance"
            v-bind:rank="selected_rank"
          />
          <Textbox
            v-else-if="value2.split(':')[0] == 'textbox'"
            :instance="instance"
            :sample="sample"
          />
          <Description
            v-else-if="value2.split(':')[0] == 'description'"
            :instance="instance"
            :sample="sample"
          />
          <IDCard
            v-else-if="value2.split(':')[0] == 'idcard'"
            :instance="instance"
            :sample="sample"
          />
          <Predictions
            v-else-if="value2.split(':')[0] == 'predictions'"
            :instance="instance"
            :sample="sample"
          />
          <Classifed
            v-else-if="value2.split(':')[0] == 'classified'"
            :instance="instance"
            :sample="sample"
          />
          <Auprc
            v-else-if="value2.split(':')[0] == 'auprc'"
            :instance="instance"
            :sample="sample"
            v-bind:rank="selected_rank"
          />
          <Prc
            v-else-if="value2.split(':')[0] == 'prc'"
            :instance="instance"
            :sample="sample"
            v-bind:rank="selected_rank"
          />
          <FilteringThreshold
            v-else-if="value2.split(':')[0] == 'filtering_threshold'"
            :instance="instance"
            :sample="sample"
            v-bind:rank="selected_rank"
          />
          <Heatmap
            v-else-if="value2.split(':')[0] == 'heatmap'"
            :instance="instance"
            :sample="sample"
            v-bind:rank="selected_rank"
          />
          <Composition
            class="double"
            v-else-if="value2.split(':')[0] == 'composition'"
            :instance="instance"
            :sample="sample"
            v-bind:rank="selected_rank"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="spacer"></div>
  </div>
  <Footer/>
</template>

<script>
// @ is an alias to /src
import Comparison from "@/components/widgets/Comparison.vue";
import RankingTable from "@/components/widgets/RankingTable.vue";
import ComparisonTable from "@/components/widgets/ComparisonTable.vue";
import Precision from "@/components/widgets/Precision.vue";
import Recall from "@/components/widgets/Recall.vue";
import Tp from "@/components/widgets/Tp.vue";
import Fp from "@/components/widgets/Fp.vue";
import L2 from "@/components/widgets/L2.vue";
import L2_rank from "@/components/widgets/L2_rank.vue";
import F1 from "@/components/widgets/F1.vue";
import Memory from "@/components/widgets/Memory.vue";
import MemoryRef from "@/components/widgets/MemoryRef.vue";
import Heatmap from "@/components/widgets/Heatmap.vue";
import RuntimeRef from "@/components/widgets/RuntimeRef.vue";
import Runtime from "@/components/widgets/Runtime.vue";
import Ranking from "@/components/widgets/Ranking.vue";
import Selector from "@/components/widgets/Selector.vue";
import Textbox from "@/components/widgets/Textbox.vue";
import Description from "@/components/widgets/Description.vue";
import IDCard from "@/components/widgets/IDCard.vue";
import Predictions from "@/components/widgets/Predictions.vue";
import Classifed from "@/components/widgets/Classified.vue";
import FilteringThreshold from "@/components/widgets/FilteringThreshold.vue";
import Auprc from "@/components/widgets/Auprc.vue";
import Prc from "@/components/widgets/Prc.vue";
import Composition from "@/components/widgets/Composition.vue";
import contentStructure from "@/../public/data/structure.json";
import Footer from '@/components/Footer.vue';
import niceTitles from "@/../public/data/nice_titles.json";

export default {
  name: "InstanceDetails",
  props: {
    instance: String,
    niceTitles: Object
  },
  components: {
    Comparison,
    ComparisonTable,
    RankingTable,
    Precision,
    Recall,
    Tp,
    Fp,
    L2,
    L2_rank,
    F1,
    Memory,
    MemoryRef,
    RuntimeRef,
    Runtime,
    Ranking,
    Selector,
    Textbox,
    Description,
    IDCard,
    Classifed,
    Heatmap,
    FilteringThreshold,
    Auprc,
    Prc,
    Composition,
    Predictions,
    Footer
  },
  data() {
    return {
      structure: contentStructure,
      selected_rank: "",
      ranks: [],
      isCollapsed: {},
      niceTitle: niceTitles[this.instance] ? niceTitles[this.instance] : this.instance
    };
  },
  mounted() {
    fetch(`data/${this.instance}.ranks.json`)
      .then((res) => res.json())
      .then((data) => {
        this.ranks = data.ranks;
        this.selected_rank = data.ranks[data.ranks.length-1];
      });
    var first = true;
    for (var sample in this.structure[this.instance]) {
      if (first) {
        first = false;
        this.isCollapsed[sample] = false;
      } else {
        this.isCollapsed[sample] = true;
      }
    }
  },
  methods: {
    change_rank(rank) {
      this.selected_rank = rank;
    },
    toggle_display(sample) {
      this.isCollapsed[sample] = !this.isCollapsed[sample];
    },
    copyURL() {
          navigator.clipboard.writeText(window.location.href).then(function() {
            alert("The URL is now in your clipboard!")
          }, function(err) {
            alert('Failed to copy the URL in your clipboard');
          });
    },
    getConfig() {
          var url =  'https://zenodo.org/record/6811338';
          window.open(url, '_blank');
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h2 {
  display: block;
  font-size: 1.3em;
  padding: 0.3em;
  margin-bottom: 0;
  color: black;
}
h3 {
  display: block;
  font-size: 1em;
  margin: 0;
  padding: 0.3em 0.3em 0em 0.3em;
}

ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
.wide_block {
  clear: both;
  margin: 0.2em 1em;
  padding: 0;
  display: block;
  background-color: #0288d1;
  color: white;
  border: 0;
  border-radius: 0.7em;
  padding: 0.5em 0 0.1em 0;
}
.floating_block {
  height: 30em;
  overflow-y: scroll;
  width: 17em;
  margin: 1em;
  float: left;
  background-color: white;
}

.full_width {
  width: 100%;
}

.double {
  width: 34em;
}
.instance_summary_description {
  margin-top: 1em;
}

.highlight {
  font-weight: 900;
  font-size: 1.1em;
  display: block;
  float:left;
}

.sample_evaluation {
  font-weight: 900;
  font-variant: small-caps;
  font-size: 1.1em;
  background-color: slategrey;
  color: white;
  border: 0.1em solid white;
  border-radius: 1em;
  padding: 0 1em;
  margin-left: 2em;
}

.sample_calibration {
  font-weight: 900;
  font-variant: small-caps;
  font-size: 1.1em;
  background-color: #000;
  color: white;
  border: 0.1em solid white;
  border-radius: 1em;
  padding: 0 1em;
  margin-left: 2em;
}

button.rank_selector {
  float: left;
  margin: 0 0 1em 0;
  padding: 0.2em;
  border: 0;
  background: none;
  cursor: pointer;
  color: white;
  border-radius: 0.3em;
}

button.active {
  color: white;
  background: #d84315;
}

.taxonomic_rank_label {
  font-size: 0.9em;
  float: left;
  margin: 0 1em 0 0;
  padding: 0.1em;
  font-weight: bold;
}

.toggle_button {
  background: white;
  color: black;
  padding: 0.3em;
  margin: 0;
  border: 0;
  border-radius: 1.5em;
  width: 1.5em;
  display: inline-block;
  position: absolute;
  right: 2em;
  cursor: pointer;
}

.collapsed {
  display: none;
}

.expanded {
  display: inline-block;
}

.fixed {
  position:fixed;
  top:8em;
  background-color:white;
  border:1px solid lightgrey;
  border-left-width:0;
  border-top-width:0;
  border-right-width:0;
  padding-top:5em;
  z-index:1999;
}

.spacer {
  clear:both;
  height:2.2em;
}

button.share {
  padding:0.4em;
  margin:0 2em;
  background-color: #d84315;
  color:white;
  font-weight: bold;
  border: 2px solid white;
  border-radius: 3px;
  cursor: pointer;
}

button.getconfig {
  padding:0.4em;
  margin:0 2em;
  background-color: #0288d1;
  color:white;
  font-weight: bold;
  border: 2px solid white;
  border-radius: 3px;
  cursor: pointer;
}

button.share:hover {
  border: 2px solid #d84315;
}

button.getconfig:hover {
  border: 2px solid #0288d1;
}


.tuto_collapse {
  color:white;
  position: absolute;
  right:8em;
  font-size: 0.6em;
  text-align: left;
}

#taxo_selection {
  color:white;
  position: fixed;
  background-color:#0288d1;
  width:100%;
  z-index:9999;
  bottom:1.7em;
  left:0;
  margin:0;
  padding:0;
  height:1.7em;
  padding-top:0.5em;
  padding-left:1.5em;
  border:0 solid white;
  border-top-width:1px;
}

.right #taxo_selection {
  margin-left: 50%;
  z-index: 99999;
}

</style>
