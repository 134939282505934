import { ref } from "@vue/runtime-core";

const getToolDetails = (instance, tools) => {
  const tool_details = ref({})
  const error = ref(null)

  const load_tool_details = async () => {
    try {
      var promises = [];
      for (let i = 0; i < tools.length; i++) {
        var toolname = tools[i];
        promises.push(
          fetch(`data/${instance}.${toolname}.json`).then(
            (res) => (res.ok && res.json()) || Promise.reject(res)
          )
        );
      }
      const data = await Promise.all(promises);
      //console.log("data", data)
      for (let i = 0; i < tools.length; i++) {
        var toolname = tools[i];
        tool_details.value[toolname] = data[i]
      }
      // tool_details.value = data
      // console.log("tool details", tool_details.value)
    } catch (err) {
      error.value = err.message;
      //console.log(error.value);
    }
  };
  return { tool_details, error, load_tool_details }
}

export default getToolDetails