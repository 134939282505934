<template>
  <div class="table">
    <ag-grid-vue
      style="width: 100%; height: 100%"
      class="ag-theme-alpine"
      :columnDefs="columnDefs"
      :rowData="rowData"
      align="center"
      :onGridReady="onGridReady"
    />
  </div>
</template>

<script>
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridVue } from "ag-grid-vue3";
import getToolDetails from "../../composables/getToolDetails";
import { ref, watchEffect } from "@vue/runtime-core";

export default {
  name: "ComparisonTable",
  props: {
    instance: String,
    rank: String,
    plot_data: Object,
    tools: Array,
    metrics: Array,
    labels: Object,
  },
  components: {
    AgGridVue,
  },
  setup(props) {
    
    const { tool_details, error2, load_tool_details } = getToolDetails(
      props.instance,
      props.tools
    );
    load_tool_details();

    const columnDefs = ref([]);
    const rowData = ref([]);

    const cellClassRules = {
      optval: (params) => params.value >= params.data.optval,
    };

    columnDefs.value.push({ headerName: "", field: "metric", resizable: true, wrapText: true, autoHeight: true, cellStyle: {wordBreak: "normal", textAlign: "left"} });
    for (let t in props.tools) {
      columnDefs.value.push({
        field: props.tools[t],
        cellClassRules: cellClassRules,
        cellRenderer: function (params) {
          if (
            params.value != "N/A" &&
            (params.data.metric == "Repository") |
              (params.data.metric == "Website") |
              (params.data.metric == "Git") |
              (params.data.metric == "Publication DOI")
          ) {
            let linktext = params.value;
            let newLink = `<a href= ${linktext}
            target="_blank">${linktext}</a>`;
            return newLink;
          } else {
            return params.value;
          }
        },
        cellStyle: {wordBreak: "normal", textAlign: "center"},
        resizable: true,
        wrapText: true, 
        autoHeight: true,
      });
    }
    watchEffect(() => {
      rowData.value = []
      if (Object.keys(tool_details.value).length > 0) {
        for (let m in props.metrics) {
          var row = {};
          var row_vals = [];
          row["metric"] = props.labels[props.metrics[m]];
          for (let t in props.tools) {
            if (props.plot_data[props.tools[t]][props.metrics[m]]) {
              let rowval =
                props.plot_data[props.tools[t]][props.metrics[m]].toFixed(2);
              row[props.tools[t]] = rowval;
              row_vals.push(rowval);
            }
          }
          if (Object.keys(row).length > 1) {
            row["optval"] = Math.max(...row_vals);
            rowData.value.push(row);
          }
        }
        var entry = tool_details.value[props.tools[0]];
        var keys = Object.keys(entry);
        for (let k in keys) {
          var row = {};
          row["metric"] = props.labels[keys[k]];
          for (let t in props.tools) {
            row[props.tools[t]] = tool_details.value[props.tools[t]][keys[k]];
          }
          rowData.value.push(row);
        }
      }
    });
    
    return {
      columnDefs,
      rowData,
      onGridReady: (params) => {
        params.api.sizeColumnsToFit();
      },
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.table {
  margin: 0;
  padding: 0;
  background-color: #fff;
  text-align: center;
  border: 0;
  height: 22em;
  color: black;
}
.optval {
  font-weight: bold;
  background-color: lightgreen;
}
.p {
  color: black;
}
.ag-header-cell-label {
   justify-content: center;
}

</style>
