import { ref } from "@vue/runtime-core";

const getMetricData = (instance, metrics) => {
  const metric_data = ref({})
  const error = ref(null)

  const load_metric_data = async () => {
    try {
      var promises = [];
      for (let i = 0; i < metrics.length; i++) {
        var metric = metrics[i]
        promises.push(
          fetch(`data/${instance}.${metric}.json`).then(
            (res) => (res.ok && res.json()) || Promise.reject(res)
          )
        );
      }
      const data = await Promise.all(promises);
      for (let ind=0; ind<data.length; ind++) {
        var metric = metrics[ind]
        metric_data.value[metric] = data[ind]
      }
    } catch (err) {
      error.value = err.message;
      //console.log(error.value);
    }
  };
  return { metric_data, error, load_metric_data }
};

export default getMetricData