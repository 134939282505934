<template>
    <div class="textbox">
    <h4>{{ box_content.title }}</h4>
    <div class="paragraph">
    {{ box_content.text1 }}
    </div>
    <div class="paragraph">
    {{ box_content.text2 }}
    </div>
    <div class="paragraph">
    {{ box_content.text3 }}
    </div>
    <div class="paragraph">
    {{ box_content.text4 }}
    </div>
    </div>
</template>

<script>
export default {
  name: 'Description',
  props: {
    instance: String,
    sample: String,
  },
  data() {
    return {
      box_content: {
        "title": "",
        "text": ""
      }
    }
  },
  mounted() {
      fetch(`data/${this.sample}.description.json`)
        .then(res => res.json())
        .then(data => this.box_content = data)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.textbox {
  margin:0;
  padding:1em;
  width:14em;
  background-color: #F8FBFD;
  text-align:left;
  border:0.3em solid #0288d1;
  border-radius: 1em;
  border-left: 0;
  border-right: 0;
  height:22em;
  color:black;
  overflow-y:scroll;
}
.paragraph {
  font-size:0.9em;
  margin-bottom:0.9em;
  text-align:left;
}
</style>
